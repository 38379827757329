<template>
  <div class="container home_page" v-loading="loading">
    <div class="pull-right">
      <div class="major-header p-20">
        <p class="title">{{ currentNode.name }}</p>
      </div>
      <div class="info m-t-20 m-b-20">
        <el-tabs type="card" v-model="rightName">
          <el-tab-pane label="职业概况" name="职业概况">
            <div class="major-info-list">
              <div class="major-info-content" v-if="currentNode.description && currentNode.description !=''">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>专业介绍</span>
                  </span>
                </div>
                <p v-html="currentNode.description"></p>
              </div>
              <div class="major-info-content" v-if="currentNode.postJobs && currentNode.postJobs.length>0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>相关岗位 <span class="fz-14" style="color: #999">({{ currentNode.postJobs.length }}个)</span></span>
                  </span>
                </div>
                <div class="post-item m-b-20" v-for="item in currentNode.postJobs">
                  <div class="post-title fz-16" style="cursor: pointer;margin-bottom: 10px" @click="clickTab(item)">
                    {{ item.name }}
                  </div>
                  <div class="post-text fz-14">
                    <div style="width: 180px;">
                      {{ item.salaryMin }}-{{ item.salaryMax }}元/月
                    </div>
                    <div style="width: 200px;" v-if="item.hotCity">
                      热门地区：{{ item.hotCity }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="major-info-content" v-if="majors && majors.length>0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>相关专业 <span class="fz-14" style="color: #999">({{ majors.length }}个)</span></span>
                  </span>
                </div>
                <div class="post-item m-b-20" v-for="item in majors">
                  <div class="post-title fz-16" style="cursor: pointer;margin-bottom: 10px" @click="changeChildrenMajor(item)">
                    {{ item.name }}
                  </div>
                  <div class="post-text fz-14">
                    <div style="width: 180px;">
                      国标代码：{{ item.id }}
                    </div>
                    <div style="width: 200px;" v-if="item.learnYearArab">
                      学制：{{ item.learnYearArab }}
                    </div>
                    <div style="width: 200px;" v-if="item.maleRatio && item.femaleRatio">
                      男女比例：{{ item.maleRatio }}:{{ item.femaleRatio }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="就业岗位" name="就业岗位">
            <div class="jobTabs m-t-30">
              <div class="tab" :class="currentJob.name == item.name?'bg-primary':''"
                   v-for="item in currentNode.postJobs" @click="clickTab(item)">
                <p class="fz-14 text-overflow-ellipsis">
                  {{ item.name }}
                </p>
                <p class="fz-14">
                  {{ item.salaryMin }}-{{ item.salaryMax }}元/月
                </p>
              </div>
            </div>
            <div class="chart-list">
              <div v-if="experienceList.length >0 || salaryList.length> 0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>薪资情况</span>
                  </span>
                </div>
                <div class="m-t-20 el-row is-justify-space-between ">
                  <div class="el-col el-col-24" v-if="experienceList.length >0">
                    <p class="subTitle fz-14">按趋势</p>
                    <div id="experience" ref="experience"
                         style="width:360px;height: 240px; -webkit-tap-highlight-color: transparent; user-select: none;"></div>
                  </div>
                  <div class="el-col el-col-24" v-if="salaryList.length >0">
                    <p class="subTitle fz-14">按分布</p>
                    <div id="salary" ref="salary"
                         style="width:360px; margin:0 auto;height: 240px; -webkit-tap-highlight-color: transparent; user-select: none;"></div>
                  </div>
                </div>
              </div>
              <div v-if="eduList.length >0 || expList.length> 0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>就业形势</span>
                  </span>
                </div>
                <div class="m-t-20 el-row is-justify-space-between ">
                  <div class="el-col el-col-24" v-if="eduList.length >0">
                    <p class="subTitle fz-14">按学历</p>
                    <div id="edu" ref="edu"
                         style="width:360px; margin:0 auto;height: 240px; -webkit-tap-highlight-color: transparent; user-select: none;"></div>
                  </div>
                  <div class="el-col el-col-24" v-if="expList.length >0">
                    <p class="subTitle fz-14">按经验</p>
                    <div id="exp" ref="exp"
                         style="width:360px; margin:0 auto;height: 240px; -webkit-tap-highlight-color: transparent; user-select: none;"></div>
                  </div>
                </div>
              </div>
              <div v-if="demandList.length>0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>招聘需求量</span>
                  </span>
                </div>
                <div class="m-t-20">
                  <div class="el-row is-justify-space-between " style="margin-left: -25px; margin-right: -25px;">
                    <div class="el-col el-col-24" style="padding-left: 25px; padding-right: 25px;">
                      <div v-for="(item,i) in demandList.slice(0,5)" class="item fz-16 el-row" style="color: #666;line-height: 44px;border-bottom: 1px solid #f2f2f2;}">
                        <div class="center fz-18 el-col el-col-3">{{i+1}}</div>
                        <div class="text-overflow-ellipsis el-col el-col-15" style="color: #333">{{ item.city }}</div>
                        <div class="fz-14 el-col el-col-6" style="text-align: right">{{ item.count }} 个职位</div>
                      </div>
                    </div>
                    <div class="el-col el-col-24" style="padding-left: 25px; padding-right: 25px;">
                      <div v-for="(item,i) in demandList.slice(5,10)" :key="i" class="item fz-16 el-row" style="color: #666;line-height: 44px;border-bottom: 1px solid #f2f2f2;}">
                        <div class="center fz-18 el-col el-col-3">{{i+6}}</div>
                        <div class="text-overflow-ellipsis el-col el-col-15" style="color: #333">{{ item.city }}</div>
                        <div class="fz-14 el-col el-col-6" style="text-align: right">{{ item.count }} 个职位</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-t-30" v-if="industrySalaryList.length>0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>收入排行-按行业</span>
                  </span>
                </div>
                <div class="">
                  <div class="el-row " style="margin-left: -25px; margin-right: -25px;">
                    <div class="el-col el-col-24" style="padding-left: 25px; padding-right: 25px;">
                      <div v-for="(item,i) in industrySalaryList.slice(0,5)" class="item fz-16 el-row" style="color: #666;line-height: 44px;border-bottom: 1px solid #f2f2f2;}">
                        <div class="center fz-18 el-col el-col-3">{{i+1}}</div>
                        <div class="text-overflow-ellipsis el-col el-col-15" style="color: #333">{{ item.name }}</div>
                        <div class="fz-14 el-col el-col-6" style="text-align: right">{{ item.salary }}元/月</div>
                      </div>
                    </div>
                    <div class="el-col el-col-24" style="padding-left: 25px; padding-right: 25px;">
                      <div v-for="(item,i) in industrySalaryList.slice(5,10)" class="item fz-16 el-row" style="color: #666;line-height: 44px;border-bottom: 1px solid #f2f2f2;}">
                        <div class="center fz-18 el-col el-col-3">{{i+6}}</div>
                        <div class="text-overflow-ellipsis el-col el-col-15" style="color: #333">{{ item.name }}</div>
                        <div class="fz-14 el-col el-col-6" style="text-align: right">{{ item.salary }}元/月</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-t-30" v-if="citySalaryList.length>0">
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>收入排行-按地区</span>
                  </span>
                </div>
                <div class="">
                  <div class="el-row " style="margin-left: -25px; margin-right: -25px;">
                    <div class="el-col el-col-24" style="padding-left: 25px; padding-right: 25px;">
                      <div v-for="(item,i) in citySalaryList.slice(0,5)" class="item fz-16 el-row" style="color: #666;line-height: 44px;border-bottom: 1px solid #f2f2f2;}">
                        <div class="center fz-18 el-col el-col-3">{{i+1}}</div>
                        <div class="text-overflow-ellipsis el-col el-col-15" style="color: #333">{{ item.city }}</div>
                        <div class="fz-14 el-col el-col-6" style="text-align: right">{{ item.salary }}元/月</div>
                      </div>
                    </div>
                    <div class="el-col el-col-24" style="padding-left: 25px; padding-right: 25px;">
                      <div v-for="(item,i) in citySalaryList.slice(5,10)" class="item fz-16 el-row" style="color: #666;line-height: 44px;border-bottom: 1px solid #f2f2f2;}">
                        <div class="center fz-18 el-col el-col-3">{{i+6}}</div>
                        <div class="text-overflow-ellipsis el-col el-col-15" style="color: #333">{{ item.city }}</div>
                        <div class="fz-14 el-col el-col-6" style="text-align: right">{{ item.salary }}元/月</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding-bottom: 80px" class="m-t-30">
                <p class="fz-14" style="color: #333">
                  数据说明:
                </p>
                <p class="fz-12" style="color: #666">职业数据来自《中华人民共和国国家职业分类大典》</p>
                <p class="fz-12" style="color: #666">薪资数据来自于职友集{{sampleDesc}}</p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      jobList: [],
      majors: [],
      activeName: '全部职业',
      rightName: '职业概况',
      params: {},
      loading: true,
      currentNode: {},
      currentJob: {},
      experience: null,
      salary: null,
      edu: null,
      exp: null,
      experienceList: [],
      salaryList: [],
      eduList: [],
      expList: [],
      demandList:[],
      citySalaryList:[],
      industrySalaryList:[],
      sampleDesc:''
    }
  },
  async created() {
    let res = await this.$axios.get('/php/job.list')
    this.jobList = res.data.data.map(e => {
      e.children = e.jobs
      return e
    })
    const { path, query } = this.$router.currentRoute;
    await this.setData()
    await this.getJobDetail()
  },
  methods: {
    async changeChildrenMajor(data){
      const eduLevel = data.eduLevel == 'ben'?'本科':'专科'
      this.$router.push({ path: "/majors/small?id="+data.id+"&parentId="+data.parentCode+"&type="+eduLevel
      });
    },
    async clickTab(data){
      this.rightName = '就业岗位'
      if(this.currentJob.name != data.name){
        this.currentJob = data
        this.loading = true
        await this.getJobDetail()
        this.loading = false
      }
    },
    async setData() {
      const res = await this.$axios.get('/php/job.detail?jobCode=' + encodeURIComponent(this.$route.query.id))
      this.currentNode = res.data.data.detail
      this.majors = res.data.data.majors
      this.currentJob = this.currentNode.postJobs.length > 0 ? this.currentNode.postJobs[0] : []
      this.loading = false
    },
    async getJobDetail() {
      const res = await this.$axios.get('/php/job.career?name=' + this.currentJob.name)
      this.experienceList = res.data.data.experience
      this.salaryList = res.data.data.salary
      this.eduList = res.data.data.edu
      this.expList = res.data.data.exp
      this.demandList = res.data.data.demand
      this.citySalaryList = res.data.data.citySalary
      this.industrySalaryList = res.data.data.industrySalary
      this.sampleDesc = res.data.data.sampleDesc
      await this.setChartOption()
    },
    setChartOption() {
      this.$nextTick(() => {
        if (this.experienceList.length > 0) {
          let experience = this.$refs["experience"];
          this.experience = this.$echarts.init(experience, "light");
          let experienceOption = {
            "yAxis": [
              {
                "type": "value",
                "show": true,
                "axisLine": {
                  "show": false,
                  "onZero": true,
                  "onZeroAxisIndex": null,
                  "lineStyle": {
                    "color": "#333",
                    "width": 1,
                    "type": "solid"
                  },
                  "symbol": [
                    "none",
                    "none"
                  ],
                  "symbolSize": [
                    10,
                    15
                  ]
                },
                "axisTick": {
                  "show": false,
                  "inside": false,
                  "length": 5,
                  "lineStyle": {
                    "width": 1
                  }
                },
                "splitLine": {
                  "lineStyle": {
                    "type": "dotted",
                    "color": [
                      "#ccc"
                    ],
                    "width": 1
                  },
                  "show": true
                },
                "axisLabel": {
                  "margin": 20,
                  "show": true,
                  "inside": false,
                  "rotate": 0,
                  "showMinLabel": null,
                  "showMaxLabel": null,
                  "fontSize": 12
                },
                "boundaryGap": [
                  0,
                  0
                ],
                "splitNumber": 5,
                "minorTick": {
                  "show": false,
                  "splitNumber": 5,
                  "length": 3,
                  "lineStyle": {}
                },
                "minorSplitLine": {
                  "show": false,
                  "lineStyle": {
                    "color": "#eee",
                    "width": 1
                  }
                },
                "zlevel": 0,
                "z": 0,
                "inverse": false,
                "name": "",
                "nameLocation": "end",
                "nameRotate": null,
                "nameTruncate": {
                  "maxWidth": null,
                  "ellipsis": "...",
                  "placeholder": "."
                },
                "nameTextStyle": {},
                "nameGap": 15,
                "silent": false,
                "triggerEvent": false,
                "tooltip": {
                  "show": false
                },
                "axisPointer": {},
                "splitArea": {
                  "show": false,
                  "areaStyle": {
                    "color": [
                      "rgba(250,250,250,0.3)",
                      "rgba(200,200,200,0.3)"
                    ]
                  }
                },
                "offset": 0,
                "rangeEnd": null,
                "rangeStart": null
              }
            ],
            "xAxis": [
              {
                "type": "category",
                "boundaryGap": false,
                "data": this.experienceList.map(e => e.year),
                "axisLine": {
                  "show": false,
                  "onZero": true,
                  "onZeroAxisIndex": null,
                  "lineStyle": {
                    "color": "#333",
                    "width": 1,
                    "type": "solid"
                  },
                  "symbol": [
                    "none",
                    "none"
                  ],
                  "symbolSize": [
                    10,
                    15
                  ]
                },
                "axisTick": {
                  "show": false,
                  "alignWithLabel": false,
                  "interval": "auto",
                  "inside": false,
                  "length": 5,
                  "lineStyle": {
                    "width": 1
                  }
                },
                "axisLabel": {
                  "color": "#666",
                  "interval": 0,
                  "show": true,
                  "inside": false,
                  "rotate": 0,
                  "showMinLabel": null,
                  "showMaxLabel": null,
                  "margin": 8,
                  "fontSize": 12
                },
                "deduplication": null,
                "splitLine": {
                  "show": false,
                  "lineStyle": {
                    "color": [
                      "#ccc"
                    ],
                    "width": 1,
                    "type": "solid"
                  }
                },
                "show": true,
                "zlevel": 0,
                "z": 0,
                "inverse": false,
                "name": "",
                "nameLocation": "end",
                "nameRotate": null,
                "nameTruncate": {
                  "maxWidth": null,
                  "ellipsis": "...",
                  "placeholder": "."
                },
                "nameTextStyle": {},
                "nameGap": 15,
                "silent": false,
                "triggerEvent": false,
                "tooltip": {
                  "show": false
                },
                "axisPointer": {},
                "splitArea": {
                  "show": false,
                  "areaStyle": {
                    "color": [
                      "rgba(250,250,250,0.3)",
                      "rgba(200,200,200,0.3)"
                    ]
                  }
                },
                "offset": 0,
                "rangeEnd": null,
                "rangeStart": null
              }
            ],
            "grid": [
              {
                "x": 60,
                "y": 30,
                "left": 60,
                "top": 30,
                "show": false,
                "zlevel": 0,
                "z": 0,
                "right": "10%",
                "bottom": 60,
                "containLabel": false,
                "backgroundColor": "rgba(0,0,0,0)",
                "borderWidth": 1,
                "borderColor": "#ccc"
              }
            ],
            "series": [
              {
                "data": this.experienceList.map(e => e.salary),
                "type": "line",
                "cursor": "pointer",
                "clipOverflow": false,
                "label": {
                  "show": true,
                  "color": "#666",
                  "position": "top"
                },
                "itemStyle": {
                  "color": "#666",
                  "borderWidth": 1
                },
                "lineStyle": {
                  "color": "#409EFF",
                  "width": 2,
                  "type": "solid"
                },
                "areaStyle": {
                  "color": {
                    "type": "linear",
                    "x": 0,
                    "y": 0,
                    "x2": 0,
                    "y2": 1,
                    "colorStops": [
                      {
                        "offset": 0,
                        "color": "rgba(64,158,255,.22)"
                      },
                      {
                        "offset": 1,
                        "color": "rgba(122,171,254,0)"
                      }
                    ],
                    "global": false
                  }
                },
                "clip": false,
                "zlevel": 0,
                "z": 2,
              }
            ],
          }
          this.experience.setOption(experienceOption);
        }
        if (this.salaryList.length > 0) {
          let salary = this.$refs["salary"];
          this.salary = this.$echarts.init(salary, "light");
          let salaryOption = {
            "axisPointer": [
              {
                "show": "auto",
                "triggerOn": null,
                "zlevel": 0,
                "z": 50,
                "type": "line",
                "snap": false,
                "triggerTooltip": true,
                "value": null,
                "status": null,
                "link": [],
                "animation": null,
                "animationDurationUpdate": 200,
                "lineStyle": {
                  "color": "#aaa",
                  "width": 1,
                  "type": "solid"
                },
                "shadowStyle": {
                  "color": "rgba(150,150,150,0.3)"
                },
                "label": {
                  "show": true,
                  "formatter": null,
                  "precision": "auto",
                  "margin": 3,
                  "color": "#fff",
                  "padding": [
                    5,
                    7,
                    5,
                    7
                  ],
                  "backgroundColor": "auto",
                  "borderColor": null,
                  "borderWidth": 0,
                  "shadowBlur": 3,
                  "shadowColor": "#aaa"
                },
              }
            ],
            "tooltip": [],
            "grid": [
              {
                "x": 0,
                "y": 0,
                "left": 0,
                "top": 0,
                "show": false,
                "zlevel": 0,
                "z": 0,
                "right": "10%",
                "bottom": 60,
                "containLabel": false,
                "backgroundColor": "rgba(0,0,0,0)",
                "borderWidth": 1,
                "borderColor": "#ccc"
              }
            ],
            "series": [
              {
                "type": "pie",
                "radius": [
                  "30%",
                  "45%"
                ],
                "label": {
                  "position": "outer",
                  "margin": 0,
                  "formatter": "{b} {c}%",
                  "fontSize": 12,
                  "color": "#545454",
                  "rotate": false,
                  "show": true,
                  "alignTo": "none",
                  "bleedMargin": 10,
                  "distanceToLabelLine": 5
                },
                "labelLine": {
                  "length": 5,
                  "length2": 5,
                  "show": true,
                  "smooth": false,
                  "lineStyle": {
                    "width": 1,
                    "type": "solid"
                  }
                },
                "data": this.salaryList.map(e => {
                  return {
                    name: (e.min / 1000) + 'K-' + (e.max / 1000) + 'K元/月',
                    value: e.ratio
                  }
                }),
                "zlevel": 0,
                "z": 2,
                "legendHoverLink": true,
                "center": [
                  "50%",
                  "50%"
                ],
                "clockwise": true,
                "startAngle": 90,
                "minAngle": 0,
                "minShowLabelAngle": 0,
                "selectedOffset": 10,
                "hoverOffset": 10,
                "percentPrecision": 2,
                "stillShowZeroSum": true,
                "left": 0,
                "top": 0,
                "right": 0,
                "bottom": 0,
                "width": null,
                "height": null,
                "itemStyle": {
                  "borderWidth": 1
                },
                "animationType": "expansion",
                "animationTypeUpdate": "transition",
                "animationEasing": "cubicOut",
                "emphasis": {
                  "label": {
                    "show": true
                  },
                  "labelLine": {
                    "show": true
                  }
                }
              }
            ],
            "visualMap": [],
            "legend": [],
            "markArea": [
              {
                "zlevel": 0,
                "z": 1,
                "tooltip": {
                  "trigger": "item"
                },
                "animation": false,
                "label": {
                  "show": true,
                  "position": "top"
                },
                "itemStyle": {
                  "borderWidth": 0
                },
                "emphasis": {
                  "label": {
                    "show": true,
                    "position": "top"
                  }
                }
              }
            ],
            "markLine": [
              {
                "zlevel": 0,
                "z": 5,
                "symbol": [
                  "circle",
                  "arrow"
                ],
                "symbolSize": [
                  8,
                  16
                ],
                "precision": 2,
                "tooltip": {
                  "trigger": "item"
                },
                "label": {
                  "show": true,
                  "position": "end",
                  "distance": 5
                },
                "lineStyle": {
                  "type": "dashed"
                },
                "emphasis": {
                  "label": {
                    "show": true
                  },
                  "lineStyle": {
                    "width": 3
                  }
                },
                "animationEasing": "linear"
              }
            ],
            "markPoint": [
              {
                "zlevel": 0,
                "z": 5,
                "symbol": "pin",
                "symbolSize": 50,
                "tooltip": {
                  "trigger": "item"
                },
                "label": {
                  "show": true,
                  "position": "inside"
                },
                "itemStyle": {
                  "borderWidth": 2
                },
                "emphasis": {
                  "label": {
                    "show": true
                  }
                }
              }
            ],
            "marker": [],
            "brush": [],
            "dataZoom": []
          }
          this.salary.setOption(salaryOption);
        }
        if (this.eduList.length > 0) {
          let edu = this.$refs["edu"];
          this.edu = this.$echarts.init(edu, "light");
          let eduOption = {
            "textStyle": {"fontFamily": "sans-serif", "fontSize": 12, "fontStyle": "normal", "fontWeight": "normal"},
            "animation": "auto",
            "animationDuration": 1000,
            "animationDurationUpdate": 300,
            "animationEasing": "exponentialOut",
            "animationEasingUpdate": "cubicOut",
            "animationThreshold": 2000,
            "progressiveThreshold": 3000,
            "progressive": 400,
            "hoverLayerThreshold": 3000,
            "useUTC": false,
            "axisPointer": [{
              "show": "auto",
              "triggerOn": null,
              "zlevel": 0,
              "z": 50,
              "type": "line",
              "snap": false,
              "triggerTooltip": true,
              "value": null,
              "status": null,
              "link": [],
              "animation": null,
              "animationDurationUpdate": 200,
              "lineStyle": {"color": "#aaa", "width": 1, "type": "solid"},
              "shadowStyle": {"color": "rgba(150,150,150,0.3)"},
              "label": {
                "show": true,
                "formatter": null,
                "precision": "auto",
                "margin": 3,
                "color": "#fff",
                "padding": [5, 7, 5, 7],
                "backgroundColor": "auto",
                "borderColor": null,
                "borderWidth": 0,
                "shadowBlur": 3,
                "shadowColor": "#aaa"
              },
            }],
            "tooltip": [],
            "grid": [{
              "x": 0,
              "y": 0,
              "left": 0,
              "top": 0,
              "show": false,
              "zlevel": 0,
              "z": 0,
              "right": "10%",
              "bottom": 60,
              "containLabel": false,
              "backgroundColor": "rgba(0,0,0,0)",
              "borderWidth": 1,
              "borderColor": "#ccc"
            }],
            "series": [{
              "type": "pie",
              "radius": ["30%", "45%"],
              "avoidLabelOverlap": true,
              "clipOverflow": false,
              "hoverAnimation": false,
              "label": {
                "position": "outer",
                "margin": 0,
                "formatter": "{b} {c}%",
                "fontSize": 12,
                "color": "#545454",
                "rotate": false,
                "show": true,
                "alignTo": "none",
                "bleedMargin": 10,
                "distanceToLabelLine": 5
              },
              "labelLine": {
                "length": 5,
                "length2": 5,
                "show": true,
                "smooth": false,
                "lineStyle": {"width": 1, "type": "solid"}
              },
              "data": this.eduList.map(e => {
                return {
                  name: e.edu,
                  value: e.ratio
                }
              }),
              "zlevel": 0,
              "z": 2,
              "legendHoverLink": true,
              "center": ["50%", "50%"],
              "clockwise": true,
              "startAngle": 90,
              "minAngle": 0,
              "minShowLabelAngle": 0,
              "selectedOffset": 10,
              "hoverOffset": 10,
              "percentPrecision": 2,
              "stillShowZeroSum": true,
              "left": 0,
              "top": 0,
              "right": 0,
              "bottom": 0,
              "width": null,
              "height": null,
              "itemStyle": {"borderWidth": 1},
              "animationType": "expansion",
              "animationTypeUpdate": "transition",
              "animationEasing": "cubicOut",
              "emphasis": {"label": {"show": true}, "labelLine": {"show": true}}
            }],
            "visualMap": [],
            "legend": [],
            "markArea": [{
              "zlevel": 0,
              "z": 1,
              "tooltip": {"trigger": "item"},
              "animation": false,
              "label": {"show": true, "position": "top"},
              "itemStyle": {"borderWidth": 0},
              "emphasis": {"label": {"show": true, "position": "top"}}
            }],
            "markLine": [{
              "zlevel": 0,
              "z": 5,
              "symbol": ["circle", "arrow"],
              "symbolSize": [8, 16],
              "precision": 2,
              "tooltip": {"trigger": "item"},
              "label": {"show": true, "position": "end", "distance": 5},
              "lineStyle": {"type": "dashed"},
              "emphasis": {"label": {"show": true}, "lineStyle": {"width": 3}},
              "animationEasing": "linear"
            }],
            "markPoint": [{
              "zlevel": 0,
              "z": 5,
              "symbol": "pin",
              "symbolSize": 50,
              "tooltip": {"trigger": "item"},
              "label": {"show": true, "position": "inside"},
              "itemStyle": {"borderWidth": 2},
              "emphasis": {"label": {"show": true}}
            }],
            "marker": [],
            "brush": [],
            "dataZoom": []
          }
          this.edu.setOption(eduOption);
        }
        if (this.expList.length > 0) {
          let exp = this.$refs["exp"];
          this.exp = this.$echarts.init(exp, "light");
          let expOption = {
            "textStyle": {"fontFamily": "sans-serif", "fontSize": 12, "fontStyle": "normal", "fontWeight": "normal"},
            "animation": "auto",
            "animationDuration": 1000,
            "animationDurationUpdate": 300,
            "animationEasing": "exponentialOut",
            "animationEasingUpdate": "cubicOut",
            "animationThreshold": 2000,
            "progressiveThreshold": 3000,
            "progressive": 400,
            "hoverLayerThreshold": 3000,
            "useUTC": false,
            "axisPointer": [{
              "show": "auto",
              "triggerOn": null,
              "zlevel": 0,
              "z": 50,
              "type": "line",
              "snap": false,
              "triggerTooltip": true,
              "value": null,
              "status": null,
              "link": [],
              "animation": null,
              "animationDurationUpdate": 200,
              "lineStyle": {"color": "#aaa", "width": 1, "type": "solid"},
              "shadowStyle": {"color": "rgba(150,150,150,0.3)"},
              "label": {
                "show": true,
                "formatter": null,
                "precision": "auto",
                "margin": 3,
                "color": "#fff",
                "padding": [5, 7, 5, 7],
                "backgroundColor": "auto",
                "borderColor": null,
                "borderWidth": 0,
                "shadowBlur": 3,
                "shadowColor": "#aaa"
              },
            }],
            "tooltip": [],
            "grid": [{
              "x": 0,
              "y": 0,
              "left": 0,
              "top": 0,
              "show": false,
              "zlevel": 0,
              "z": 0,
              "right": "10%",
              "bottom": 60,
              "containLabel": false,
              "backgroundColor": "rgba(0,0,0,0)",
              "borderWidth": 1,
              "borderColor": "#ccc"
            }],
            "series": [{
              "type": "pie",
              "radius": ["30%", "45%"],
              "avoidLabelOverlap": true,
              "clipOverflow": false,
              "hoverAnimation": false,
              "label": {
                "position": "outer",
                "margin": 0,
                "formatter": "{b} {c}%",
                "fontSize": 12,
                "color": "#545454",
                "rotate": false,
                "show": true,
                "alignTo": "none",
                "bleedMargin": 10,
                "distanceToLabelLine": 5
              },
              "labelLine": {
                "length": 5,
                "length2": 5,
                "show": true,
                "smooth": false,
                "lineStyle": {"width": 1, "type": "solid"}
              },
              "data": this.expList.map(e=>{
                return {
                  name:e.exp,
                  value:e.ratio
                }
              }),
              "zlevel": 0,
              "z": 2,
              "legendHoverLink": true,
              "center": ["50%", "50%"],
              "clockwise": true,
              "startAngle": 90,
              "minAngle": 0,
              "minShowLabelAngle": 0,
              "selectedOffset": 10,
              "hoverOffset": 10,
              "percentPrecision": 2,
              "stillShowZeroSum": true,
              "left": 0,
              "top": 0,
              "right": 0,
              "bottom": 0,
              "width": null,
              "height": null,
              "itemStyle": {"borderWidth": 1},
              "animationType": "expansion",
              "animationTypeUpdate": "transition",
              "animationEasing": "cubicOut",
              "emphasis": {"label": {"show": true}, "labelLine": {"show": true}}
            }],
            "visualMap": [],
            "legend": [],
            "markArea": [{
              "zlevel": 0,
              "z": 1,
              "tooltip": {"trigger": "item"},
              "animation": false,
              "label": {"show": true, "position": "top"},
              "itemStyle": {"borderWidth": 0},
              "emphasis": {"label": {"show": true, "position": "top"}}
            }],
            "markLine": [{
              "zlevel": 0,
              "z": 5,
              "symbol": ["circle", "arrow"],
              "symbolSize": [8, 16],
              "precision": 2,
              "tooltip": {"trigger": "item"},
              "label": {"show": true, "position": "end", "distance": 5},
              "lineStyle": {"type": "dashed"},
              "emphasis": {"label": {"show": true}, "lineStyle": {"width": 3}},
              "animationEasing": "linear"
            }],
            "markPoint": [{
              "zlevel": 0,
              "z": 5,
              "symbol": "pin",
              "symbolSize": 50,
              "tooltip": {"trigger": "item"},
              "label": {"show": true, "position": "inside"},
              "itemStyle": {"borderWidth": 2},
              "emphasis": {"label": {"show": true}}
            }],
            "marker": [],
            "brush": [],
            "dataZoom": []
          }
          this.exp.setOption(expOption);
        }
      })
    },
    changeJob(data) {

    },
    async changeVocationalLibrary(data) {
      this.params = {
        id:data.id,
        parentId:data.parentCode
      }
      this.loading = true
      await this.setData()
      await this.getJobDetail()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" >
.tooltip {
  width: 350px;
  padding: 30px;
  border: none !important;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

  .small {
    cursor: pointer;
    margin-bottom: 10px;
    color: #666;
    float: left;
  }

  .small:hover {
    color: #910000;
  }
}

.container {
  overflow: hidden;

  .text-primary {
    color: #910000 !important;
  }

  .pull-left {
    float: left;
    width: 260px;
    margin-right: 40px;
    margin-bottom: 20px;

    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #910000;
      background-color: #fff;
      border-right-color: #dcdfe6;
      border-left-color: #dcdfe6;
    }

    .custom-tree-node {
      flex: 1;
      padding-right: 10px;
    }
  }

  .pull-right {
    // width: 900px;
    // float: right;

    .major-header {
      height: 114px;
      position: relative;
      background: rgba(233, 48, 45, 0.08) !important;

      .title {
        color: #333;
        line-height: 40px;
        font-size: 28px;
        font-weight: 500;
      }

      .desc {
        color: #999;
        line-height: 20px;
        font-size: 14px;
      }
    }

    .info {
      a[href] {
        color: #333;
      }

      .y-header-title {
        border-left: 3px solid #910000;
        padding-left: 10px;
        margin-bottom: 30px;
      }

      .major-item {
        padding: 15px 0 10px;
        border-bottom: 1px solid #f2f2f2;

        .label {
          color: #333;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .label:hover {
          color: #910000;
        }

        .desc {
          color: #999;
        }
      }

      .el-tabs--card > .el-tabs__header {
        border-bottom: 1px solid #910000;
      }

      .el-tabs__item.is-active {
        color: #fff;
      }

      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background: #910000;
        border-radius: 4px 4px 0 0;
      color: #fff !important;
      }

      .el-tabs--card > .el-tabs__header .el-tabs__item {
        border: none;
        font-size: 16px;
        padding: 0 33px !important;
      }

      .el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
      }

      .major-info-content {
        margin-bottom: 43px;

        p {
          font-size: 14px;
          color: #666;
          line-height: 22px;
        }

        .post-item {
          border-bottom: 1px solid #f2f2f2;

          .post-title {
            color: #333;
            font-weight: 500;
          }

          .post-text {
            width: 700px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            color: #666;
            padding-bottom: 10px;
          }

          .post-text div:not(:first-child):before {
            content: "";
            display: inline-block;
            width: 1px;
            height: 12px;
            background-color: #ccc;
            vertical-align: text-top;
            margin-right: 60px;
            margin-top: 4px;
          }
        }
      }
    }
  }
  .el-tab-pane{
        padding: 15px;
        background: #fff;
      }

  .jobTabs {
    overflow: hidden;
display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .tab {
      padding: 10px;
      width: 48%;
      height: 66px;
      background: #f2f2f2;
      border-radius: 4px;
      margin-bottom: 20px;

      p {
        line-height: 20px !important;
        margin-bottom: 5px !important;
      }

      .text-overflow-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .tab:nth-of-type(5n) {
      margin-right: 0;
    }

    .bg-primary {
      background: #910000 !important;
      background-color: #910000 !important;
      color: #fff !important;
    }
  }

  .chart-list {

    .subTitle {
      color: #333;
      line-height: 20px;
      text-align: right;
    }
  }
}
</style>